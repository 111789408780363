import { graphql } from 'gatsby';
import React, {useLayoutEffect} from 'react';
import { string } from 'prop-types';

export const GaOptOut = ({ id, gaid, confirmationText }) => {


  useLayoutEffect(() => {
    const gaProperty = `${gaid}`;
    const disableStr = 'ga-disable-' + gaProperty;

    if (document.cookie.indexOf(disableStr + '=true') > -1) {
      window[disableStr] = true;
    }

    function initLoadEvent() {
      document.querySelector('.ga-optout').addEventListener('click', function(e) {
        e.preventDefault();
        document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        window[disableStr] = true;
        alert(confirmationText); // Google Analytics tracking is now deactivated
      });
    }

    if (document.readyState === "complete") {
      initLoadEvent();
    } else {
      window.addEventListener("load", initLoadEvent);
      return () => {
        window.removeEventListener('load', initLoadEvent)
      }
    }
  }, []);

  return (
    <></>
  )
};

export const fragment = graphql`
  fragment GaOptOutContentFragment on CraftGQL_pageContents_gaoptout_BlockType {
    id
    gaid
    confirmationText
  }
`;

GaOptOut.propTypes = {
  id: string.isRequired,
};
